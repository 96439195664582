import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

export const useAreaTable = () => {
  // COmposables
  const { t } = useI18n();

  const headers = computed<Header[]>(() => {
    const headersArray: Header[] = [
      {
        label: t('global.name'),
        key: 'name',
      },
      {
        label: t('global.energies'),
        key: 'energies',
      },
      {
        label: t('global.type'),
        key: 'type',
      },
      {
        label: t('area.enabled_feeds'),
        key: 'reporting-type',
      },
    ];
    if (globalStore().getSelectedSite.length === 1) {
      headersArray.push({
        label: '',
        key: 'action',
        className: 'w-[100px]',
      });
    }
    return headersArray;
  });

  const actions: TableAction[] = [
    {
      key: 'edit',
      icon: 'Pen',
      label: t('global.edit'),
    },
  ];

  return { headers, actions };
};
