<script lang="ts" setup>
const { headers, actions } = useAreaTable();

// Composables
const router = useRouter();

// Stores
const areaService = areaStore();

// Methods
const tableActionClick = ($event: string, id: number) => {
  if ($event === 'edit' && globalStore().getSelectedSite.length === 1) {
    router.push(`/configuration/areas/edit/${id}`);
  }
};
</script>
<template>
  <ui-card :title="$t('global.areas')">
    <template #header>
      <ui-button v-if="globalStore().getSelectedSite.length === 1" left-icon="Plus" @click="router.push('/configuration/areas/create')">
        {{ $t('area.add_area') }}
      </ui-button>
    </template>

    <!-- Table -->
    <ui-data-table :headers="headers" clickable :items="areaService.areas" @click-row="tableActionClick('edit', $event.id)">
      <template #item-energies="{ item }">
        <div class="flex gap-2">
          <ui-badge
            v-for="meter in item.meters"
            :key="meter.id"
            :color="meter.meter_type.energy_type === 'Electricity' ? 'green' : meter.meter_type.energy_type === 'Gas' ? 'orange' : 'blue'"
          >
            {{ $t(`global.energy_type.${meter.meter_type.energy_type}`) }}
          </ui-badge>
        </div>
      </template>
      <template #item-type="{ item }">
        <ui-badge color="blue">
          {{ $t(`area.area_types.${item.area_type}`) }}
        </ui-badge>
      </template>
      <template #item-reporting-type="{ item }">
        <div class="flex gap-2">
          <ui-badge v-if="item.is_fabrication_order_reporting_enabled" color="gray">
            {{ $t('global.fabrication_order') }}
          </ui-badge>
          <ui-badge v-if="item.is_production_reporting_enabled" color="gray">
            {{ $t('global.production') }}
          </ui-badge>
          <template v-if="!item.is_production_reporting_enabled && !item.is_fabrication_order_reporting_enabled">
            {{ $t('global.none') }}
          </template>
        </div>
      </template>
      <template #item-action="{ item }">
        <ui-data-table-actions
          v-if="globalStore().getSelectedSite.length === 1"
          :items="actions"
          :item="item"
          @click-item="tableActionClick($event, item.id)"
        />
      </template>
    </ui-data-table>
  </ui-card>
</template>
